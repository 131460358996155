.content-light-bg {
    .section-title {
        @apply w-full items-center text-center #{!important};
        width: 100% !important;
    }

    .section-title-wrapper {
        @apply w-full items-center text-center;
        span {
            @apply w-full mx-auto;
        }
        h2 {
            @apply normal-case #{!important};
            font-weight: 700;
            font-size: 3rem !important;
            line-height: 1 !important;

            strong {
                @apply normal-case #{!important};
                font-weight: 700;
                font-size: 3rem !important;
                line-height: 1 !important;
            }

            small {
                @apply uppercase #{!important};
            }
        }

        i {
            @apply hidden;
        }
    }
}
.continue {
    @apply rounded-none shadow-none pt-8;

}
.gallery {
    padding-top: 2rem;
    grid-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    width: 100%;

    &.cols-1 {
        grid-template-columns: 1fr;
        padding-top: 0rem;

        // padding-left:2rem;
        >* {
            height: auto !important;
            @apply w-full aspect-auto;
        }

        video {
            @apply w-full;
        }
    }

    &.cols-2 {
        grid-template-columns: 1fr;
        padding-top: 0rem;

        // padding-left:2rem;
        >* {
            height: auto !important;
        }
    }

    >* {
        @apply object-cover rounded-md shadow-lg overflow-hidden transition-all ease-in-out duration-200;
        height: 25vh;
        cursor: pointer;

        
    }


    &.masonry {
        grid-template-columns: 1fr 1fr;

        >* {
            height: 10vh;

            &:first-child {
                grid-row: 1 / 2;
                grid-column: 1 / 3;
                height: calc(20vh + 2rem);
            }

            &:nth-child(2) {
                grid-column: 1 / 2;
                grid-row: 2 / 4;
                height: calc(20vh + 2rem);
            }
        }
    }


    img {
        @apply rounded-md overflow-hidden;
    }

    @screen md {
        grid-template-columns: 1fr 1fr 1fr;

        &.cols-1 {
            grid-template-columns: 1fr;

            >* {
                height: auto !important;
            }
        }

        &.cols-2 {
            grid-template-columns: 1fr;

            >* {
                height: auto !important;
            }
        }

        >* {
            height: 20vh;

            &:hover {
                @apply scale-110 z-20 bg-trust-900;

                img {
                    @apply opacity-50 ease-in-out scale-125;
                }
            }

            &.continue {
                &:hover {
                    @apply bg-transparent scale-100;
                }
            }
        }

        &.masonry {
            grid-template-columns: 1fr 1fr 1fr;

            >* {
                height: 20vh;

                &:first-child {
                    grid-row: 1 / 3;
                    grid-column: 1 / 3;
                    height: calc(40vh + 2rem);
                }

                &:nth-child(2) {
                    grid-column: auto;
                    grid-row: auto;
                    height: 20vh;
                }

                &:nth-child(3) {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &:nth-child(8) {
                    grid-row: 3 / 4;
                    grid-column: 2 / 4;
                }
            }
        }

        img {
            @apply transition-all ease-in-out duration-500;
        }
    }

    @screen lg {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        &.cols-1 {
            grid-template-columns: 1fr;

            >* {
                height: auto !important;
            }
        }

        &.cols-2 {
            grid-template-columns: 1fr 1fr;

            >* {
                height: auto !important;
            }
        }

        >* {
            height: 20vh;
        }

        &.masonry {
            grid-template-columns: 1fr 1fr 1fr 1fr;

            >* {
                height: 20vh;

                &:first-child {
                    grid-row: 1 / 3;
                    grid-column: 1 / 3;
                    height: calc(40vh + 2rem);
                }

                &:nth-child(2) {
                    grid-column: auto;
                    grid-row: auto;
                    height: 20vh;
                }

                &:nth-child(3) {
                    grid-row: 1 / 2;
                    grid-column: 4 / 5;
                }

                &:nth-child(8) {
                    grid-row: 3 / 5;
                    grid-column: 3 / 5;
                    height: calc(40vh + 2rem);
                }
            }
        }
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.css';
@import "./globals/vars";
@import "./globals/icons";
@import "./globals/elements";
@import "./globals/navbar";
@import "./globals/hero";
@import "./globals/utilities";
@import "./globals/header-subpage";
@import "./globals/gallery";
@import "./globals/consent";
@import "./globals/article";
@import "./globals/toast";
@import "./globals/accordion";
@import "./globals/slider";
@import "./_landing";
@import "./globals/showcase-box-list";
@import "./globals/content-box-simple";

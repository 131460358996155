
.landing-header {

    h1 {
        @apply text-white mb-4 text-6xl leading-[0.8];
        small {
            @apply uppercase font-normal text-xl;
        }
    }
    h1, p {
        text-shadow: 0 4px 8px rgba(0,0,0,.6);
    }
    p {
        @apply text-lg py-4;
    }

    .footnote {
        @apply font-serif mt-20 leading-[1.2];
        img {
            @apply mr-4;
        }
    }

    .landing-header-bg {
        span {
            left:-50% !important;
        }
    }
}

i.text-gradient {
    &:before {
        background: linear-gradient(45deg,#c10257 20%,#0044bc);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.text-gradient {
    &:before {
        background: linear-gradient(45deg,#c10257 20%,#0044bc);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
span.text-gradient {
    > * {
       
           
            background: linear-gradient(45deg,#c10257 20%,#0044bc);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
    }
}

.bg-darker {
    background: #131315;
}
.text-darker {
    color: #131315;
}
.bg-dark {
    background: #2a2a2d;
}

.text-dark {
    color: #2a2a2d;
}

.bg-medium-gray {
    background: #b9bec8;
}
.bg-light-gray {
    background: #edeef1;
}


#contact {
    p {
        @apply text-maturity-400;
    }
}
.bg-dark,.bg-darker, .bg-black {
    .detail-list, .detail-box {
        li:not([class^="text-"]) {
            @apply text-maturity-400;
        }
        span:not([class^="text-"]) {
            p {
                @apply text-maturity-400;
                strong {
                    color: white;
                }
            }
        }
    }
}

.text-black {
    color:black;
}
.text-green {
    color: #8de069;
}
.text-huge {
    font-size: 5rem;
    line-height: 5rem;
}


.detail-list {
 
    h2 {
        @apply text-4xl font-semibold leading-[1.2] normal-case;
    }

    h3, .h3-size h2 {
        @apply text-xl font-semibold normal-case;
    }
    h4 {
        @apply text-2xl font-semibold ;
        text-transform: none;
    }

    span:not(.text-left) {
        h2, h3, h4 {
            @apply text-center;
        }
    }

    
    i {
        font-size:4rem;
    }
}
.inverted {
    @apply invert;
}

.detail-box {

    span:not(.text-center) {
        h2, h3, h4 {
            text-align: left;
        }
    }

    ul {
        @apply flex flex-col w-full mt-4 list-inside list-disc;

        li {
            @apply py-2 border-t border-gray-500 border-opacity-30;
            p {
                @apply inline;
            }
        }
    }
}

.detail-box-title {
    p {
        @apply py-4;
        text-align: center;

    }
}
.mt-big {
    @apply mt-12;
}

.my-big {
    @apply my-12;
}

.box-gradient {
    background: rgb(81,166,245);
    background:  radial-gradient(at left center, rgba(230,0,0,1) -20%,rgba(68,106,204,1) 90%);
   
}
.text-red-light {
    color: #d1c3cd;
}
.text-gray-light {
    @apply text-gray-200;
}
.text-trust-darker{
    @apply text-trust-500;
}
.bg-gray {
    background:#666666;
}

@screen md {
    .landing-header {
        h1 {
            @apply text-7xl;
        }
        h1, p {
            text-shadow: none;
        }

        h2 {
            @apply text-5xl font-semibold leading-[1.2] ;
        }
        .landing-header-bg {
            span {
                left: 0 !important;
            }
        }
    }

    .detail-list {
        h2 {
            @apply text-5xl normal-case;
        }
    
        h3, .h3-size h2 {
            @apply text-xl font-semibold;
        }
        
        h4 {
            @apply text-2xl font-semibold;
            text-transform: none;
        }
    }
    
}
.landing-box {
    h2 {
        font-size:3rem;
        line-height: 2.8rem;
    }
}

.gray-gradient-right {
    background: linear-gradient(90deg,rgba(103,110,122, 1) 50%,rgba(103,110,122, 0) 70%);
}
@screen lg {
    .landing-header-wrapper {
        min-height: max(850px, 70vh);
        
    }
}
@tailwind utilities;

.z-2 {
    z-index: 2;
}

.client-list {
    @apply py-8;
    .text-top {
        .section-title-wrapper,
        .section-title {
            @apply text-center justify-center;
        }

        .section-title-wrapper {
            h2 {
                @apply text-5xl text-action normal-case;
                font-weight: 600;
            }
            i {
                @apply hidden;
            }
        }
    }
}
.testimonials {
    .testimonials-title {
        @apply flex flex-col w-full p-4 xl:px-0 justify-center text-center pt-8;
        h2 {
            @apply text-5xl text-action;
            font-weight: 600;
        }
    }
}
.simple-box-list {
    @apply gap-12 mt-8;
    > * {
        @apply border-0 shadow-none w-full md:w-1/2 xl:w-1/3;
    }
    .box-image {
        @apply aspect-video h-auto;
    }
    .box-content {
        @apply p-4;
    }
}
.features-box {
    @apply w-full;
    .features-box-wrapper {
        @apply flex flex-col max-w-screen-xl mx-auto pt-16;

        .section-title {
            // @apply justify-center text-center;
            i {
                @apply hidden;
            }
            h2 {
                @apply text-5xl text-action;
                font-weight: 800;
            }
            h3 {
                @apply text-black text-2xl;
                font-weight: 800;
            }
            .section-title-wrapper {
                // @apply justify-center text-center;
                span {
                    @apply normal-case;
                }
            }
        }
    }
    &.smooth-style {
        h2,
        h3 {
            color: #4c617a !important;
        }
        i {
            padding: 2rem;
            background: #fafafa;
            @apply rounded-md;
        }
        i::before {
            background: #565d64;
            background-clip: text;
        }
    }
}
.cta-box {
    h2 {
        @apply text-5xl text-action;
        font-weight: 800;
    }
    h3 {
        @apply text-black text-4xl;
        font-weight: 600;
    }

    &.bg-action {
        h2,
        h3,
        p {
            @apply text-white;
        }
    }
}

.title-box {
    @apply mt-8  px-2 xl:px-0;
    h2 {
        @apply text-5xl text-action-500 pb-4;
        font-weight: 700;
        small {
            @apply uppercase text-sm;
            color: #2b2e32;
            font-weight: 600;
        }
    }
    h3 {
        @apply text-black text-2xl;
        font-weight: 600;
    }
}
.white-box {
    line-height: 1.8;
    @apply rounded-[0.25rem] flex flex-col lg:flex-row max-w-screen-xl mx-auto  overflow-hidden;
    h2,
    h3 {
        font-weight: 600;
    }
    h2 {
        @apply text-4xl text-action;
    }
    h3 {
        @apply text-black text-2xl;
    }
    .photo-wrapper {
        span {
            @apply rounded-[0.25rem] overflow-hidden;
        }
    }
}
.dark-blue-box {
    line-height: 1.8;
    @apply rounded-[0.25rem] flex flex-col lg:flex-row max-w-screen-xl mx-auto text-white overflow-hidden;
    h2 {
        color: #f15a5a;
        font-weight: 600;
        @apply text-4xl;
    }
    background-color: #243e68;
    .photo-wrapper {
        &:before {
            content: "";
            @apply absolute z-10 w-full h-full;
            background: linear-gradient(90deg, rgba(36, 62, 104, 1) 1%, rgba(36, 62, 104, 0) 35%);
        }

        span {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.angle-box {
    line-height: 1.8;
    .photo-wrapper {
        span {
            width: 100% !important;
            height: 100% !important;
        }
    }
    h2 {
        @apply text-action text-4xl;
        font-weight: 600;
    }
}

.angle-box-gradient {
    @apply text-white;
    line-height: 1.8;
    h2 {
        @apply text-white  text-4xl;
        font-weight: 600;
    }
}
.skew-top-full,
.skew-top,
.skew-top-border {
    position: absolute;
    top: 0;
    left: 0;
    border-width: 0 0 10rem 100vw;
    border-color: transparent transparent transparent white;
    border-style: solid;
    z-index: 5;

    &.skew-maturity-100 {
        border-color: transparent transparent transparent theme("colors.maturity.100");
    }
}
.skew-top-border {
    top: 1px;
    border-color: transparent transparent transparent theme("colors.maturity.100");
}

.skew-top-full {
    border-color: transparent transparent transparent theme("colors.maturity.100");
}

.skew-top-left {
    position: absolute;
    top: -0;
    left: 0;
    border-width: 0 100vw 10rem 0;
    border-color: transparent white transparent transparent;
    border-style: solid;
    z-index: 5;

    &.skew-maturity-100 {
        border-color: transparent transparent transparent theme("colors.maturity.100");
    }
}

.skew-top-left-dark {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    width: 0;
    height: 0;
    border-top: 7rem solid transparent;
    border-bottom: 3rem solid transparent;
    border-left: 30vw solid #05142b;
}

.skew-bottom,
.skew-bottom-border {
    position: absolute;
    bottom: 0;
    left: 0;
    border-width: 10rem 100vw 0 0;
    border-color: transparent white transparent transparent;
    border-style: solid;
    z-index: 5;

    &.maturity-100 {
        border-color: transparent theme("colors.maturity.100") transparent transparent;
    }
}
.skew-bottom-border {
    bottom: 1px;
    border-color: transparent theme("colors.maturity.100") transparent transparent;
}

.skew-bottom-right {
    position: absolute;
    bottom: 0;
    left: 0;
    border-width: 0 100vw 10rem 0;
    border-color: transparent transparent white transparent;
    border-style: solid;
    z-index: 5;
}
@screen md {
    .bg-gradient-maturity-side {
        background: linear-gradient(90deg, theme("colors.maturity.100") 20%, transparent 20%);
    }
}

.bg-gradient-trust {
    background: rgb(81, 166, 245);
    background: radial-gradient(at left top, rgba(230, 0, 0, 1) 20%, rgba(151, 0, 0, 0) 60%),
        linear-gradient(45deg, rgba(81, 166, 245, 1) 0%, rgba(29, 141, 245, 1) 23%, rgba(0, 57, 151, 1) 75%);
    h2,
    h3,
    h4 {
        color: white;
    }
}
.bg-gradient-trust-left {
    h2,
    h3 {
        small {
            color: white;
            text-transform: uppercase;
        }
    }
    h2 {
        text-transform: uppercase;
    }
    p {
        color: white;
    }
    background: rgb(81, 166, 245);
    background: radial-gradient(at right top, rgba(230, 0, 0, 1) 0%, rgba(151, 0, 0, 0) 30%),
        linear-gradient(45deg, rgba(81, 166, 245, 1) 0%, rgba(29, 141, 245, 1) 10%, rgba(0, 57, 151, 1) 40%);
    @screen md {
        background: radial-gradient(at right top, rgba(230, 0, 0, 1) 10%, rgba(151, 0, 0, 0) 60%),
            linear-gradient(45deg, rgba(81, 166, 245, 1) 00%, rgba(29, 141, 245, 1) 10%, rgba(0, 57, 151, 1) 40%);
    }
}

.bg-gradient-footer {
    background: rgb(81, 166, 245);
    background: linear-gradient(135deg, rgba(230, 0, 0, 0.7) 20%, rgba(151, 0, 0, 0) 60%),
        linear-gradient(45deg, rgba(81, 166, 245, 1) 0%, rgba(29, 141, 245, 1) 23%, rgba(0, 57, 151, 1) 75%);
}

.bg-corner-red {
    xackground: radial-gradient(at left top, rgba(230, 0, 0, 1) 20%, rgba(151, 0, 0, 0) 60%);
}

.bg-hand {
    &:before {
        background: url("/images/hand.webp") 0 102% no-repeat;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.up-10 {
    margin-top: -2.5rem;
}

.up-20 {
    margin-top: 0;
    @screen sm {
        margin-top: -5rem;
    }
}

.up-30 {
    margin-top: 0;
    @screen sm {
        margin-top: -7.5rem;
    }
}

.up-40 {
    margin-top: 0;
    @screen sm {
        margin-top: -10rem;
    }
}

.up-50 {
    margin-top: 0;
    @screen sm {
        margin-top: -12.5rem;
    }
}

.box {
    padding: 4rem 2rem;
    background-color: white;
    text-align: center;
    @apply rounded-xl shadow-xl;
    flex-direction: column;
    transition: transform 0.1s ease;
    transform-style: preserve-3d;
    will-change: transform;
    @apply transition-all duration-500;

    i {
        margin: 2rem auto;
        font-size: 5rem;
        @apply text-gradient;
        @apply transition-all duration-500;
    }
    a,
    button {
        @apply transition-all duration-500;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 2rem;
        color: theme("colors.action.DEFAULT");
        @apply transition-all duration-500;
    }

    .feature-content {
        @apply transition-all duration-500;
        transform-style: preserve-3d;
    }

    p {
        font-weight: 400;
        color: #545c67;
        -webkit-line-clamp: 5; /* number of lines to show */
        line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @apply p-0 my-1;
    }
    .feature-item-wrapper {
        perspective: 2000px;
        transform-style: preserve-3d;
    }

    &:hover {
        perspective: 2000px;
        h3,
        .feature-content {
            perspective: 2000px;
            transform: translateZ(30px);
        }
        i {
            perspective: 2000px;
            transform: translateZ(50px) scale(1.1);
        }

        button {
            perspective: 2000px;
            transform: translateZ(50px);
        }
    }
}

.modal {
    ul,
    ol {
        list-style-type: circle;
        list-style-position: inside;
        @apply my-2;
    }
    p {
        @apply my-2;
    }
}

.bg-net-gray,
.bg-net-gray-red,
.bg-net-blue {
    @apply -mt-60 pt-72;
    background: url("/images/bg-net-gray.webp") no-repeat;
    background-size: auto;
    background-position: top left;

    h2 {
        @apply mb-0 text-trust-500 #{!important};

        font-size: 1.7rem !important;
    }
    h2,
    h3 {
        font-weight: 600 !important;
    }
    .grid > * {
        @apply flex-col;
    }
}
.bg-net-gray-red {
     background-position: bottom left;
    h2 {
        @apply text-action text-6xl font-bold #{!important};
        small {
            @apply text-default uppercase text-sm font-semibold;
        }
    }
    .feature-item {
        @apply flex-row items-start;
        i {
            @apply p-4 rounded-md bg-white border border-gray-100 text-maturity;
        }
    }
}
.bg-lighter-gray {
    background: #fafafa;
    h2 {
        @apply text-5xl text-action-500 #{!important};
    }
    h2 small,
    h3 {
        color: #385474;
        font-weight: 600 !important;
    }
}

.lighter-bg {
    background: rgba(255, 255, 255, 0.2);
}


.bg-dark-gray-flat,
.bg-dark-gray {
    background: linear-gradient(180deg, #202429, #26292e);
    h2 {
        @apply text-5xl text-white #{!important};
        font-weight: 700 !important;
    }
    h3 {
        font-weight: 600 !important;
    }
    h2 small,
    h3 small {
        @apply uppercase text-action-500;
        font-weight: 600 !important;
    }
    i {
        @apply text-gray-500 #{!important};
    }
    .section-title {
        p {
            @apply text-gray-400;
        }
    }
}
.bg-dark-gray-flat {
    background: #202429;
}

.bg-net-blue {
    background: url("/images/bg-net-blue.webp") no-repeat;
}
.mart-64 {
    @apply mt-64;
}
.mart-40 {
    @apply mt-40;
}
.marb-0 {
    @apply mb-0 #{!important};
}
.marb-240px {
    margin-bottom: 240px;
}
.-mart-240px {
    margin-top: -240px;
}

.marb-24 {
    @apply mb-24;
}

.marb-12 {
    @apply mb-12;
}

.mart-12 {
    @apply mt-12;
}

.padb-64 {
    @apply pb-64;
}

.padt-64 {
    @apply pt-64;
}

.padb-48 {
    @apply pb-48;
}

.padt-48 {
    @apply pt-48;
}

.padb-32 {
    @apply pb-32;
}

.padt-32 {
    @apply pt-32;
}

.padb-24 {
    @apply pb-24;
}

.padt-24 {
    @apply pt-24;
}

.padb-12 {
    @apply pb-12;
}

.padt-12 {
    @apply pt-12;
}
.padt-8 {
    @apply pt-8 #{!important};
}
.padb-8 {
    @apply pb-8 #{!important};
}
.no-padding {
    padding: 0 !important;
}

.bg-net {
    @apply py-72 pb-48 relative;
    background: #0b0f22 url("/images/bg-net-color.webp") no-repeat;
    background-position: bottom;
    background-size: cover;

    .features-box-wrapper > * {
        @apply m-0;
    }

    .section-title {
        @apply items-start justify-start #{!important};
        p {
            @apply text-white;
        }
        .section-title-wrapper {
            @apply text-left #{!important};
            h2 {
                @apply mb-8 text-white text-4xl;
            }
        }
    }

    .feature-item {
        @apply m-0 p-4 border-b border-b-white border-opacity-20;
        &:last-child {
            @apply border-0;
        }
        > div {
            @apply w-full flex-row;
        }
        h3 {
            @apply flex flex-grow order-2 text-white font-normal uppercase text-xl;
        }

        button {
            @apply flex order-2 items-center;
        }

        .feature-content {
            @apply m-0 text-white;
        }
    }

    .grid > * {
        @apply flex-col;
    }
}

.dark-list,
.light-list {
    @apply pb-48 relative;
    background: #26292e url("/images/bg-dark.jpg") no-repeat;
    background-position: top left;
    background-size: cover;

    .section-title {
        @apply items-center text-center justify-start #{!important};
        p {
            @apply text-white;
        }
        .section-title-wrapper {
            @apply text-center #{!important};
            h2 {
                @apply mb-8 text-white text-4xl;
                small {
                    @apply text-action text-base;
                }
            }
        }
    }
    .grid {
        @apply w-auto mx-auto grid-cols-2 md:grid-cols-4;
    }

    .feature-item {
        @apply m-0 p-4 text-white rounded-md flex flex-col items-center aspect-square w-[9rem] h-[9rem] mx-auto justify-center;
        background-color: rgba(0, 0, 0, 0.5);

        .feature-item-wrapper {
            h3 {
                @apply text-white text-center text-sm font-normal;
            }
        }

        .feature-content {
            @apply m-0 text-white;
        }
    }
}

.light-list {
    background: none;
    .section-title {
        @apply items-center text-center justify-start #{!important};
        p {
            @apply text-default;
        }
        .section-title-wrapper {
            @apply text-center #{!important};
            h2,h3 {
                @apply mb-8 text-red-700 text-4xl #{!important};
                small {
                    @apply text-default text-base;
                }
            }
        }
    }

    .feature-item {
        @apply backdrop-blur-sm m-0 p-4 pt-8 mx-2 text-white text-left rounded-md flex flex-col items-start flex-grow min-h-[14rem] justify-start;
        background-color: rgba(245, 245, 245, 0.3);

        .feature-item-wrapper {
            h3 {
                @apply text-trust-500 text-left text-base font-semibold;
            }
        }
        i {
            @apply text-default;
        }

        .feature-content {
            @apply m-0 text-default;
        }
    }
    .grid {
        @apply w-full flex flex-wrap gap-0;
    }
}
.mt-48 {
    margin-top: 24rem;
}
.large-h3 {
    &h3,
    h3 {
        @apply text-8xl;
        font-weight: 700 !important;
        small {
            @apply text-xl uppercase;
            font-weight: 700 !important;
        }
    }
}

.bg-inovation {
    background: #f9f9f9 url("/images/bg-innovation.webp") no-repeat;
    background-size: cover;
    // @apply py-24;
    p {
        @apply text-white;
    }
    h1, h2, h3 {
        @apply text-white text-center;
        small {
            @apply text-gray-900 uppercase font-semibold;
        }
    }
}

.cols-2-text {
    p {
        padding: 0 !important;
    }
    column-count: 1;
    @screen md {
        column-count: 2;
    }
}
.cols-3-text {
    p {
        padding: 0 !important;
    }
    column-count: 1;
    @screen md {
        column-count: 3;
    }
}

.angled-gray-net {
    background: #f9f9f9 url("/images/bg-net-full-gray.webp") no-repeat;
    background-size: auto;
    background-position: left 60%;
    @apply pb-64 -mb-64 z-10 relative max-w-full w-full #{!important};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 9vw), 0 100%);

    > * {
        @apply py-0 #{!important};
    }
}

.bg-gray-blue-net:before {
    content: "";
    @apply w-full top-[-1px] z-0 absolute h-[400px];
    background: url("/images/bg-net-gray-blue2.jpg") no-repeat;
    background-position: left top;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 150px), 0 100%);
}

.angled-boxes {
    @apply py-24 items-center justify-center relative z-20;

    &.items-1 {
        .content-box {
            @apply w-full lg:w-1/2;
        }
    }

    &.items-2 {
        .content-box {
            @apply w-full md:w-[80%] lg:w-[40%];
        }
    }

    &.items-2 {
        .content-box {
            &:nth-child(even) {
                .content-box-photo {
                    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 100px), 0 calc(100% - 50px));
                }
            }
        }
    }

    &.items-3 {
        .content-box {
            @apply w-full md:w-[60%];
        }
    }

    .content-box {
        @apply relative rounded-xl bg-white shadow-xl border-none w-full md:w-1/2 xl:w-1/3 min-h-[40rem] overflow-hidden;

        .box-content {
            h2,
            h3 {
                @apply text-3xl text-action-500;
                text-transform: none;
                small {
                    @apply text-sm text-gray-800 uppercase;
                    font-weight: 600;
                }
            }
            p {
                //minimum 6 lines of content
                @apply min-h-[9rem];
                -webkit-line-clamp: 5; /* number of lines to show */
                line-clamp: 5;
                -webkit-box-orient: vertical;
            }
        }
        &:hover {
            .content-box-photo {
                transform: scale(1.05);
            }
        }

        &:nth-child(2n) {
            .content-box-photo {
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 100px), 0 calc(100% - 50px));
            }
        }
        &:nth-child(3n) {
            .content-box-photo {
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 150px), 0 calc(100% - 100px));
            }
        }

        .content-box-photo {
            will-change: transform;
            background-clip: content-box;
            transition: all 0.5s;
            border: 0;
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%);
        }
    }
}

.bg-net-red {
    @apply relative z-10 w-full max-w-full pt-32;

    background:
        linear-gradient(0deg, rgba(192, 0, 0, 0) 70%, rgba(140, 0, 0, 1)),
        url("/images/bg-net-red.webp") no-repeat,
        rgba(192, 0, 0, 1);
    background-position: bottom left;
    // background-size: 100%;
    background-repeat: no-repeat;

    > * {
        @apply max-w-screen-xl mx-auto;
    }

    h2,
    h3 {
        @apply text-white;
    }
}

.aspect-3-2 {
    aspect: 3/2;
}

.angled-top {
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 150px);
    @apply -mt-[150px];
}
.angled-bottom {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 150px), 0 100%);
    @apply -mb-[150px] relative z-20;
}

.bg-footer {
    background: radial-gradient(at -20% 20%, rgb(230, 0, 0) 0%, rgba(150, -1, 0, 0) 57%),
        radial-gradient(at 60% 0%, rgb(40, 81, 127) 10%, rgb(0, 0, 0) 72%);
}

.no-filter {
    filter: none;
    video {
        filter: none;
    }
}

.job-description {
    h2 {
        @apply text-3xl mb-2;
    }
    ul {
        @apply list-disc;
    }
}

.image-box {
    @apply relative z-10 transition-all duration-300;
    &:after {
        @apply transition-all duration-1000;
    }

    &:nth-child(1n) {
        &:after {
            content: "";
            @apply absolute z-0 w-1/2 h-1/4 bg-gray-50 rounded-xl bottom-4 -right-8;
        }
    }
    &:nth-child(2n) {
        &:after {
            content: "";
            @apply absolute z-0 w-2/3 h-2/3 bg-gray-50 rounded-xl -bottom-20 -right-20;
        }
    }

    &:hover {
        transform: scale(1.05);
        &:after {
            transform: scale(0.6);
        }
        .image {
            transform: scale(1.1);
        }
    }
    p {
        @apply text-maturity-500;
    }
    .image-box-wrapper {
        @apply border border-gray-200 rounded-xl overflow-hidden relative z-10 bg-white;
    }

    .image {
        @apply aspect-square w-full transition-all duration-500;
        > * {
            @apply w-full;
        }
        span {
            @apply w-full #{!important};
        }
    }
}

@layer utilities {
    .job-spacing {
        .image-box {
            &:nth-child(2n) {
                @apply mt-32;
            }
        }

        &.lg\:grid-cols-3 {
            @apply gap-8 #{!important};
        }
    }
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 1px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    @apply rounded-md;
}

.wide-form {
    @apply bg-white rounded-xl p-10 max-w-screen-xl mx-auto w-full #{!important};
    h2 {
        @apply text-4xl font-normal my-4;
    }
    form {
    }
}

.min-h-80h {
    min-height: 80vh !important;
}

.min-h-350 {
    min-height: 350px;
}


.itemsStretch {
    @apply items-stretch;
}

.content-box-content {
    strong {
        text-transform: none;
        @apply text-xl;
    }
}

.box-dots {
    &.img-up {
        .content-box-image {
            margin-top: -6rem;
            margin-bottom: 5rem;
        }
    }
    .content-box-zig-zag {
        .content-box-content {
            align-self: center;
            margin-bottom: 10rem;
            strong {
                text-transform:none;
            }
        }
        .content-box-image {
            border-radius: 0.75rem;
            &::after {
                content: "";
                @apply absolute w-1/2 h-1/4 bg-gray-50 rounded-xl -bottom-8 -right-8 -z-10;
                @apply transition-all duration-1000;
                background-color: rgba(255, 255, 255, 0.3);
            }
        }
    }

    @screen lg {
        &.img-up {
            .content-box-image {
                margin-bottom: 10rem;
            }
        }
        .content-box-zig-zag {
            margin-bottom: 0rem;
        }
    }
}

.bg-red {
    background-color: #c00000 !important;
    h1,
    h2,
    h3 {
        color: white;
        small {
            color: #26292e !important;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
        }
    }
    p {
        color: white;
    }
}

.text-dark-gray {
    color: #4c617a !important;
}

.content-light-bg {
    h2,
    h3 {
        @apply text-action #{!important};
        small {
            @apply text-default uppercase text-sm font-semibold;
        }
    }

    p {
        @apply text-default;
    }
}

.content-bottom {
    .container {
        margin-bottom: 0;
    }
}

.text-center {
    img {
        margin: 0 auto;
    }
}

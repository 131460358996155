$flaticon_arka-font: "arka";

@font-face {
    font-family: $flaticon_arka-font;
    src:
        url("./arka.woff2?47c1dc6e79b1902c4d957e72ce1a32af") format("woff2"),
        url("./arka.woff?47c1dc6e79b1902c4d957e72ce1a32af") format("woff"),
        url("./arka.eot?47c1dc6e79b1902c4d957e72ce1a32af#iefix") format("embedded-opentype"),
        url("./arka.ttf?47c1dc6e79b1902c4d957e72ce1a32af") format("truetype"),
        url("./arka.svg?47c1dc6e79b1902c4d957e72ce1a32af#flaticon_arka") format("svg");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: arka !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$flaticon_arka-map: (
    "global-network": "\f101",
    "plug-and-play": "\f102",
    "admin": "\f103",
    "deal": "\f104",
    "test": "\f105",
    "cpu": "\f106",
    "admin-panel": "\f107",
    "tablet": "\f108",
    "fast-delivery": "\f109",
    "custom": "\f10a",
    "encrypted": "\f10b",
    "clock-1": "\f10c",
    "windows": "\f10d",
    "linux": "\f10e",
    "rss": "\f10f",
    "easy": "\f110",
    "adaptable": "\f111",
    "cloud-based": "\f112",
    "parcel-scan": "\f113",
    "locker": "\f114",
    "parcel-location": "\f115",
    "parcel-map": "\f116",
    "delivered": "\f117",
    "startup": "\f118",
    "sla": "\f119",
    "detection": "\f11a",
    "keypad": "\f11b",
    "electrical-panel": "\f11c",
    "transistor": "\f11d",
    "gsm-signal-tower": "\f11e",
    "gps-phone": "\f11f",
    "printer": "\f120",
    "face-detection": "\f121",
    "fleet": "\f122",
    "alarm-button": "\f123",
    "battery": "\f124",
    "alarm": "\f125",
    "router": "\f126",
    "pos": "\f127",
    "warranty": "\f128",
    "resize": "\f129",
    "modular": "\f12a",
    "pallete": "\f12b",
    "pin-verification": "\f12c",
    "rust": "\f12d",
    "move": "\f12e",
    "low-cost": "\f12f",
    "strong": "\f130",
    "angle": "\f131",
    "layers": "\f132",
    "sizes": "\f133",
    "work-time": "\f134",
    "candle-chart": "\f135",
    "range": "\f136",
    "noise": "\f137",
    "update": "\f138",
    "multitask": "\f139",
    "iso-symbol": "\f13a",
    "secure-shield": "\f13b",
    "conveyer": "\f13c",
    "loading": "\f13d",
    "trolley-load": "\f13e",
    "width": "\f13f",
    "height": "\f140",
    "barcode": "\f141",
    "multi-ops": "\f142",
    "workflow": "\f143",
    "cogwheel-turning": "\f144",
    "gear-change": "\f145",
    "certificate": "\f146",
    "diploma": "\f147",
    "solar-panel": "\f148",
    "speedometer": "\f149",
    "boxes": "\f14a",
    "drops": "\f14b",
    "magnetic-field": "\f14c",
    "magnet": "\f14d",
    "weight-loss": "\f14e",
    "structure": "\f14f",
    "light-weight": "\f150",
    "bluetooth": "\f151",
    "battery-life": "\f152",
    "life-guard": "\f153",
    "chat": "\f154",
    "send-email": "\f155",
    "human-resources": "\f156",
    "control": "\f157",
    "telephone": "\f158",
    "email": "\f159",
    "edit": "\f15a",
    "location": "\f15b",
    "handshake": "\f15c",
    "search": "\f15d",
    "ask": "\f15e",
    "bar-chart": "\f15f",
    "clock": "\f160",
    "technical-support": "\f161",
    "customer-support": "\f162",
    "teaching": "\f163",
    "settings": "\f164",
    "truck": "\f165",
    "deposit": "\f166",
    "planet-earth": "\f167",
    "eco-friendly": "\f168",
    "calendar": "\f169",
    "video-camera": "\f16a",
    "phone-pay-01": "\f16b",
    "phone-pay-02": "\f16c",
    "columns": "\f16d",
    "column-size": "\f16e",
    "column-numbered": "\f16f",
    "column-bullet": "\f170",
    "power-plug": "\f171",
    "puzzle": "\f172",
    "blacksmith": "\f173",
    "led-light": "\f174",
    "uv-protection": "\f175",
    "paint": "\f176",
    "scalability": "\f177",
    "touch": "\f178",
    "qr-code": "\f179",
    "shield": "\f17a",
    "android": "\f17b",
    "customize": "\f17c",
    "outdoors": "\f17d",
    "facebook": "\f17e",
    "twitter": "\f17f",
    "linkedin": "\f180",
    "youtube": "\f181",
    "instagram": "\f182",
    "whatsapp": "\f183",
    "expand": "\f184",
    "back": "\f185",
    "minimize": "\f186",
    "next": "\f187",
    "left-arrow": "\f188",
    "right-arrow": "\f189",
    "up-arrow": "\f18a",
    "down-arrow": "\f18b",
    "bookmark": "\f18c"
);

.flaticon-global-network:before {
    content: map-get($flaticon_arka-map, "global-network");
}
.flaticon-plug-and-play:before {
    content: map-get($flaticon_arka-map, "plug-and-play");
}
.flaticon-admin:before {
    content: map-get($flaticon_arka-map, "admin");
}
.flaticon-deal:before {
    content: map-get($flaticon_arka-map, "deal");
}
.flaticon-test:before {
    content: map-get($flaticon_arka-map, "test");
}
.flaticon-cpu:before {
    content: map-get($flaticon_arka-map, "cpu");
}
.flaticon-admin-panel:before {
    content: map-get($flaticon_arka-map, "admin-panel");
}
.flaticon-tablet:before {
    content: map-get($flaticon_arka-map, "tablet");
}
.flaticon-fast-delivery:before {
    content: map-get($flaticon_arka-map, "fast-delivery");
}
.flaticon-custom:before {
    content: map-get($flaticon_arka-map, "custom");
}
.flaticon-encrypted:before {
    content: map-get($flaticon_arka-map, "encrypted");
}
.flaticon-clock-1:before {
    content: map-get($flaticon_arka-map, "clock-1");
}
.flaticon-windows:before {
    content: map-get($flaticon_arka-map, "windows");
}
.flaticon-linux:before {
    content: map-get($flaticon_arka-map, "linux");
}
.flaticon-rss:before {
    content: map-get($flaticon_arka-map, "rss");
}
.flaticon-easy:before {
    content: map-get($flaticon_arka-map, "easy");
}
.flaticon-adaptable:before {
    content: map-get($flaticon_arka-map, "adaptable");
}
.flaticon-cloud-based:before {
    content: map-get($flaticon_arka-map, "cloud-based");
}
.flaticon-parcel-scan:before {
    content: map-get($flaticon_arka-map, "parcel-scan");
}
.flaticon-locker:before {
    content: map-get($flaticon_arka-map, "locker");
}
.flaticon-parcel-location:before {
    content: map-get($flaticon_arka-map, "parcel-location");
}
.flaticon-parcel-map:before {
    content: map-get($flaticon_arka-map, "parcel-map");
}
.flaticon-delivered:before {
    content: map-get($flaticon_arka-map, "delivered");
}
.flaticon-startup:before {
    content: map-get($flaticon_arka-map, "startup");
}
.flaticon-sla:before {
    content: map-get($flaticon_arka-map, "sla");
}
.flaticon-detection:before {
    content: map-get($flaticon_arka-map, "detection");
}
.flaticon-keypad:before {
    content: map-get($flaticon_arka-map, "keypad");
}
.flaticon-electrical-panel:before {
    content: map-get($flaticon_arka-map, "electrical-panel");
}
.flaticon-transistor:before {
    content: map-get($flaticon_arka-map, "transistor");
}
.flaticon-gsm-signal-tower:before {
    content: map-get($flaticon_arka-map, "gsm-signal-tower");
}
.flaticon-gps-phone:before {
    content: map-get($flaticon_arka-map, "gps-phone");
}
.flaticon-printer:before {
    content: map-get($flaticon_arka-map, "printer");
}
.flaticon-face-detection:before {
    content: map-get($flaticon_arka-map, "face-detection");
}
.flaticon-fleet:before {
    content: map-get($flaticon_arka-map, "fleet");
}
.flaticon-alarm-button:before {
    content: map-get($flaticon_arka-map, "alarm-button");
}
.flaticon-battery:before {
    content: map-get($flaticon_arka-map, "battery");
}
.flaticon-alarm:before {
    content: map-get($flaticon_arka-map, "alarm");
}
.flaticon-router:before {
    content: map-get($flaticon_arka-map, "router");
}
.flaticon-pos:before {
    content: map-get($flaticon_arka-map, "pos");
}
.flaticon-warranty:before {
    content: map-get($flaticon_arka-map, "warranty");
}
.flaticon-resize:before {
    content: map-get($flaticon_arka-map, "resize");
}
.flaticon-modular:before {
    content: map-get($flaticon_arka-map, "modular");
}
.flaticon-pallete:before {
    content: map-get($flaticon_arka-map, "pallete");
}
.flaticon-pin-verification:before {
    content: map-get($flaticon_arka-map, "pin-verification");
}
.flaticon-rust:before {
    content: map-get($flaticon_arka-map, "rust");
}
.flaticon-move:before {
    content: map-get($flaticon_arka-map, "move");
}
.flaticon-low-cost:before {
    content: map-get($flaticon_arka-map, "low-cost");
}
.flaticon-strong:before {
    content: map-get($flaticon_arka-map, "strong");
}
.flaticon-angle:before {
    content: map-get($flaticon_arka-map, "angle");
}
.flaticon-layers:before {
    content: map-get($flaticon_arka-map, "layers");
}
.flaticon-sizes:before {
    content: map-get($flaticon_arka-map, "sizes");
}
.flaticon-work-time:before {
    content: map-get($flaticon_arka-map, "work-time");
}
.flaticon-candle-chart:before {
    content: map-get($flaticon_arka-map, "candle-chart");
}
.flaticon-range:before {
    content: map-get($flaticon_arka-map, "range");
}
.flaticon-noise:before {
    content: map-get($flaticon_arka-map, "noise");
}
.flaticon-update:before {
    content: map-get($flaticon_arka-map, "update");
}
.flaticon-multitask:before {
    content: map-get($flaticon_arka-map, "multitask");
}
.flaticon-iso-symbol:before {
    content: map-get($flaticon_arka-map, "iso-symbol");
}
.flaticon-secure-shield:before {
    content: map-get($flaticon_arka-map, "secure-shield");
}
.flaticon-conveyer:before {
    content: map-get($flaticon_arka-map, "conveyer");
}
.flaticon-loading:before {
    content: map-get($flaticon_arka-map, "loading");
}
.flaticon-trolley-load:before {
    content: map-get($flaticon_arka-map, "trolley-load");
}
.flaticon-width:before {
    content: map-get($flaticon_arka-map, "width");
}
.flaticon-height:before {
    content: map-get($flaticon_arka-map, "height");
}
.flaticon-barcode:before {
    content: map-get($flaticon_arka-map, "barcode");
}
.flaticon-multi-ops:before {
    content: map-get($flaticon_arka-map, "multi-ops");
}
.flaticon-workflow:before {
    content: map-get($flaticon_arka-map, "workflow");
}
.flaticon-cogwheel-turning:before {
    content: map-get($flaticon_arka-map, "cogwheel-turning");
}
.flaticon-gear-change:before {
    content: map-get($flaticon_arka-map, "gear-change");
}
.flaticon-certificate:before {
    content: map-get($flaticon_arka-map, "certificate");
}
.flaticon-diploma:before {
    content: map-get($flaticon_arka-map, "diploma");
}
.flaticon-solar-panel:before {
    content: map-get($flaticon_arka-map, "solar-panel");
}
.flaticon-speedometer:before {
    content: map-get($flaticon_arka-map, "speedometer");
}
.flaticon-boxes:before {
    content: map-get($flaticon_arka-map, "boxes");
}
.flaticon-drops:before {
    content: map-get($flaticon_arka-map, "drops");
}
.flaticon-magnetic-field:before {
    content: map-get($flaticon_arka-map, "magnetic-field");
}
.flaticon-magnet:before {
    content: map-get($flaticon_arka-map, "magnet");
}
.flaticon-weight-loss:before {
    content: map-get($flaticon_arka-map, "weight-loss");
}
.flaticon-structure:before {
    content: map-get($flaticon_arka-map, "structure");
}
.flaticon-light-weight:before {
    content: map-get($flaticon_arka-map, "light-weight");
}
.flaticon-bluetooth:before {
    content: map-get($flaticon_arka-map, "bluetooth");
}
.flaticon-battery-life:before {
    content: map-get($flaticon_arka-map, "battery-life");
}
.flaticon-life-guard:before {
    content: map-get($flaticon_arka-map, "life-guard");
}
.flaticon-chat:before {
    content: map-get($flaticon_arka-map, "chat");
}
.flaticon-send-email:before {
    content: map-get($flaticon_arka-map, "send-email");
}
.flaticon-human-resources:before {
    content: map-get($flaticon_arka-map, "human-resources");
}
.flaticon-control:before {
    content: map-get($flaticon_arka-map, "control");
}
.flaticon-telephone:before {
    content: map-get($flaticon_arka-map, "telephone");
}
.flaticon-email:before {
    content: map-get($flaticon_arka-map, "email");
}
.flaticon-edit:before {
    content: map-get($flaticon_arka-map, "edit");
}
.flaticon-location:before {
    content: map-get($flaticon_arka-map, "location");
}
.flaticon-handshake:before {
    content: map-get($flaticon_arka-map, "handshake");
}
.flaticon-search:before {
    content: map-get($flaticon_arka-map, "search");
}
.flaticon-ask:before {
    content: map-get($flaticon_arka-map, "ask");
}
.flaticon-bar-chart:before {
    content: map-get($flaticon_arka-map, "bar-chart");
}
.flaticon-clock:before {
    content: map-get($flaticon_arka-map, "clock");
}
.flaticon-technical-support:before {
    content: map-get($flaticon_arka-map, "technical-support");
}
.flaticon-customer-support:before {
    content: map-get($flaticon_arka-map, "customer-support");
}
.flaticon-teaching:before {
    content: map-get($flaticon_arka-map, "teaching");
}
.flaticon-settings:before {
    content: map-get($flaticon_arka-map, "settings");
}
.flaticon-truck:before {
    content: map-get($flaticon_arka-map, "truck");
}
.flaticon-deposit:before {
    content: map-get($flaticon_arka-map, "deposit");
}
.flaticon-planet-earth:before {
    content: map-get($flaticon_arka-map, "planet-earth");
}
.flaticon-eco-friendly:before {
    content: map-get($flaticon_arka-map, "eco-friendly");
}
.flaticon-calendar:before {
    content: map-get($flaticon_arka-map, "calendar");
}
.flaticon-video-camera:before {
    content: map-get($flaticon_arka-map, "video-camera");
}
.flaticon-phone-pay-01:before {
    content: map-get($flaticon_arka-map, "phone-pay-01");
}
.flaticon-phone-pay-02:before {
    content: map-get($flaticon_arka-map, "phone-pay-02");
}
.flaticon-columns:before {
    content: map-get($flaticon_arka-map, "columns");
}
.flaticon-column-size:before {
    content: map-get($flaticon_arka-map, "column-size");
}
.flaticon-column-numbered:before {
    content: map-get($flaticon_arka-map, "column-numbered");
}
.flaticon-column-bullet:before {
    content: map-get($flaticon_arka-map, "column-bullet");
}
.flaticon-power-plug:before {
    content: map-get($flaticon_arka-map, "power-plug");
}
.flaticon-puzzle:before {
    content: map-get($flaticon_arka-map, "puzzle");
}
.flaticon-blacksmith:before {
    content: map-get($flaticon_arka-map, "blacksmith");
}
.flaticon-led-light:before {
    content: map-get($flaticon_arka-map, "led-light");
}
.flaticon-uv-protection:before {
    content: map-get($flaticon_arka-map, "uv-protection");
}
.flaticon-paint:before {
    content: map-get($flaticon_arka-map, "paint");
}
.flaticon-scalability:before {
    content: map-get($flaticon_arka-map, "scalability");
}
.flaticon-touch:before {
    content: map-get($flaticon_arka-map, "touch");
}
.flaticon-qr-code:before {
    content: map-get($flaticon_arka-map, "qr-code");
}
.flaticon-shield:before {
    content: map-get($flaticon_arka-map, "shield");
}
.flaticon-android:before {
    content: map-get($flaticon_arka-map, "android");
}
.flaticon-customize:before {
    content: map-get($flaticon_arka-map, "customize");
}
.flaticon-outdoors:before {
    content: map-get($flaticon_arka-map, "outdoors");
}
.flaticon-facebook:before {
    content: map-get($flaticon_arka-map, "facebook");
}
.flaticon-twitter:before {
    content: map-get($flaticon_arka-map, "twitter");
}
.flaticon-linkedin:before {
    content: map-get($flaticon_arka-map, "linkedin");
}
.flaticon-youtube:before {
    content: map-get($flaticon_arka-map, "youtube");
}
.flaticon-instagram:before {
    content: map-get($flaticon_arka-map, "instagram");
}
.flaticon-whatsapp:before {
    content: map-get($flaticon_arka-map, "whatsapp");
}
.flaticon-expand:before {
    content: map-get($flaticon_arka-map, "expand");
}
.flaticon-back:before {
    content: map-get($flaticon_arka-map, "back");
}
.flaticon-minimize:before {
    content: map-get($flaticon_arka-map, "minimize");
}
.flaticon-next:before {
    content: map-get($flaticon_arka-map, "next");
}
.flaticon-left-arrow:before {
    content: map-get($flaticon_arka-map, "left-arrow");
}
.flaticon-right-arrow:before {
    content: map-get($flaticon_arka-map, "right-arrow");
}
.flaticon-up-arrow:before {
    content: map-get($flaticon_arka-map, "up-arrow");
}
.flaticon-down-arrow:before {
    content: map-get($flaticon_arka-map, "down-arrow");
}
.flaticon-bookmark:before {
    content: map-get($flaticon_arka-map, "bookmark");
}

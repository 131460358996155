.showcase-box-list {
    @apply relative pt-24 pb-16 px-8 xl:px-0;
    .showcase-box-list-wrapper {
       @apply relative;  
    }
}


.showcase-box {
    @apply z-20 relative mt-16 w-full bg-white bg-opacity-10 rounded-xl text-left text-white  my-4 overflow-hidden;
    .showcase-box-wrapper {
        @apply p-12 w-full;
    }

    .showcase-description {
        @apply text-left my-4;
    }
    .showcase-icons {
        i {
            @apply text-white #{'!important'}
        }
    }
}
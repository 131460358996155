section {
    article {
        margin-bottom: 6rem;
        h1 {
            @apply text-[2rem] mt-4 pb-4 font-bold;
            line-height: 1.4;
        }
        h2 {
            @apply text-xl mt-12 font-bold text-trust-500;
        }

        h3 {
            @apply text-lg mt-12 font-semibold text-trust-500;
        }

        strong {
            @apply text-gray-700 font-semibold;
        }
        p {
            text-align: justify;
            line-height: 1.8;
        }
        ul, ol {
            list-style: disc;
            margin-bottom:2rem;
            list-style-position: inside;
        }

        a:not(.btn)  {
            @apply text-orange-600 underline hover:text-red-700 hover:no-underline;
            
        }

        img {
            @apply rounded-lg;
        }
    }
}
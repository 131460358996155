.content-box-simple {
    @apply mt-12;
    .content-box {
        @apply border-none shadow-none flex flex-row lg:flex-col justify-start items-start;
        .content-box-photo {
            @apply flex-grow-0 lg:h-96 aspect-[2/3] #{'!important'} ;
            @apply rounded-md overflow-hidden;
        }

        .content-box-content {
            @apply pt-0 lg:pt-10 lg:pl-0 #{'!important'};
            @apply w-2/3 lg:w-full;
        }
    }
}

.items-3 {
    .content-box{
        @apply w-full lg:w-[60%];
    }
}
#small-nav {
    @apply transition-all duration-300 ease-in-out max-h-[80vh] md:overflow-hidden;
    overflow-y: auto;
}

.scrolled {
    @screen lg {  
        #small-nav {
            @apply -mt-12 opacity-0;
        }
    
        div[id^="headlessui-popover-panel"] {
            @apply transition-all;
            top:4rem;
        }
    }
}
nav {
    @apply grid gap-y-10 bg-white;
    &.menuWithSuggestions {
        @apply p-8 sm:py-12 sm:px-6 xl:pr-12 lg:px-12 sm:grid-cols-2 sm:gap-x-8;
        li {
            @apply py-2;
        }
    }
    &.standalone {
        padding: 0;
        h3 {
            padding: 1rem 2rem;
        }
        .bottomLinks {
            padding: 0 2rem 2rem 2rem;
        }
        li {
            margin: 0;
            a {
                @apply border-b-2 border-neutral-50 py-4 px-8;
                &:hover {
                    @apply bg-neutral-50;
                }
            }
        }
    }
}

.navbar-menu {
    display:flex;
    flex-direction: column;
    row-gap: 6px;
    margin-top:6px;
    div {
        width: 40px;
        height:4px;
        background-color: white;
        border-radius: 1px;
        
    }
}
.header-product {
    height: 100vh;

    background: rgb(81, 166, 245);
    @apply w-full flex items-stretch;
    background: rgb(81, 166, 245);
    background: radial-gradient(at -20% -20%, rgba(230, 0, 0, 1) 20%, rgba(151, 0, 0, 0) 60%),
        linear-gradient(45deg, rgba(81, 166, 245, 1) 0%, rgba(29, 141, 245, 1) 23%, rgba(0, 57, 151, 1) 75%);
    padding-top: 100px;

    img {
        max-height: min(40vh, calc(100vh - 100px)) !important;
    }

    @screen md {
        padding-top: 80px;
        max-height: 600px;
        img {
            max-height: min(calc(100vh - 100px), 500px) !important;
        }
    }
    @screen xl {
        padding-top: 100px;
    }
}

.header-subpage {
    max-height: 800px;
    background: rgb(81, 166, 245);
    @apply w-full flex items-stretch;

    @screen md {
        max-height: auto;
    }
}


.hero-section {
    min-height: 120vh;
    background: rgb(81, 166, 245);
    @apply w-full flex items-stretch;
    line-height: 1.5;

    .container{
        @apply pt-0 #{!important};
    }

    .hero-section-content {
        @apply w-full md:w-2/3 lg:w-1/2 flex flex-col justify-start p-10 ;
        
    }
    &.content-right {
        .hero-section-content {
            @apply ml-1/4 md:ml-1/3 lg:ml-1/2;
            @media screen and (max-width: 1024px) and (orientation: landscape) {
                @apply min-h-[800px] justify-center  pl-20;
                
            }
        }
    }

    h2,
    h3 {
        color: white;
        line-height: 1.2;
        small {
            @apply text-action-500 uppercase text-lg;
            font-weight: 600 !important;
        }
    }
    p {
        line-height: 1.5;
    }

    .hero-section-bg {
        // background-position:right;
        @apply bg-[#2c2f34] bg-opacity-10 #{!important};
        background-opacity: 0.1;
    }

    @screen md {
        max-height: auto;
        &.fit-horizontal {
            // @apply xl:aspect-video;
        }
    }

    &.fit-horizontal {
        min-height: auto;

        .hero-section-bg {
            // background-size: 100%;
            @apply bg-opacity-100;
        }

        .container {
            @apply py-24;
        }
    }
}
.subpage-gradient {
    background: radial-gradient(at -20% -20%, rgba(230, 0, 0, 1) 20%, rgba(151, 0, 0, 0) 60%),
        linear-gradient(45deg, rgba(81, 166, 245, 1) 0%, rgba(29, 141, 245, 1) 23%, rgba(0, 57, 151, 1) 75%);
}

.h1-smaller {
  h1 {
    @apply text-4xl xl:text-5xl #{'!important'};
  }
}

.h3-action {
  h3 {
    @apply text-action-500 #{'!important'};
  }
}

.header-large {
  @apply relative h-[80vh] xl:h-[90vh] overflow-hidden;
  max-height: none !important;
   > :first-child {
    @apply flex items-center;
     background: radial-gradient(at -20% -20%, rgb(33, 34, 53) 20%, rgba(151, 0, 0, 0) 60%), linear-gradient(45deg, rgb(44, 68, 90) 0%, rgb(15, 31, 46) 23%, rgb(27, 33, 41) 75%)
    }
   p {
        @apply text-base py-4;
        font-weight: 300;
      }

      small {
        display: block;
        @apply text-xl;
      }
      h1 {
        @apply text-5xl xl:text-6xl ;
        &.h1-smaller {
          @apply text-4xl xl:text-5xl font-semibold;
        }
      }
}

.no-skew {
  .skew-bottom {
    display: none !important;
  }
}

.hero-video {
  @apply relative h-[100vh] xl:h-[90vh] overflow-hidden;

  // max-height: 850px;
  display: flex;
  .content-wrapper {
    @apply relative max-w-screen-xl w-full mx-auto flex flex-col items-start justify-center;
    height: 90%;

    .button-list {
      @apply z-10 relative;
      a,
      button {
        @apply z-10 inline-block relative;
      }
    }

    .hero-video-content {
      @apply relative flex flex-col w-full z-10  xl:w-2/3 max-w-screen-xl  text-white;

      p {
        @apply text-base py-4;
        font-weight: 300;
      }

      small {
        display: block;
        @apply text-xl;
      }
      h1 {
        @apply text-5xl xl:text-6xl;
        &.h1-smaller {
          @apply text-4xl xl:text-5xl font-semibold;
        }
      }
    }
  }

  video {
    @apply absolute object-cover top-1/2 left-1/2 z-0 w-full h-full;
    transform: translateX(-50%) translateY(-50%);
    filter: brightness(50%);
  }

  .overlay {
    mix-blend-mode: multiply;
    position: absolute;
    z-index: 4;
    background: url("/images/dot-background.jpg") repeat-x;
    background-position: bottom center;
    @apply w-full h-full  opacity-20 overflow-hidden;

    background-size: 200%;
    @screen lg {
      background-size: auto;
    }
  }
}
.hero {
  h1,
  h2 {
    font-size: 3.75rem;
    line-height: 1;
    font-weight: 800;
  }

  &.home {
    height: 90vh;
    max-height: 850px;
    display: flex;

    > * {
      background-color: rgba(0, 57, 151, 1);
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
    }

    .dark-side {
      flex-basis: 0%;
      background: #05142b url("/images/bg-home-dark.webp") top right no-repeat;
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0 75%);
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .react-slideshow-container {
    height: 90vh;
    max-height: 850px;
    display: flex;
    align-items: stretch;

    .react-slideshow-fadezoom-wrapper {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        border-width: 10rem 100vw 0 0;
        border-color: transparent white transparent transparent;
        border-style: solid;
        z-index: 2;
        width: 100%;
      }
    }

    .react-slideshow-fadezoom-wrapper,
    .react-slideshow-fadezoom-images-wrap,
    .react-slideshow-fadezoom-images-wrap > * {
      // display: flex !important;
      align-items: stretch;
      height: 90vh;
      max-height: 850px;
    }

    .nav {
      &:disabled {
        @apply text-maturity-100;
        &:hover {
          @apply text-maturity-100;
        }
      }
      &:first-of-type {
        right: 5rem;
        bottom: 2rem;
        left: auto;
      }
      &:last-of-type {
        right: 2rem;
        bottom: 2rem;
        left: auto;
      }
    }

    & + ul.indicators {
      @apply flex -top-[4rem] mt-0 relative justify-end;
      width: calc(100% - 9rem);
      z-index: 3;
      li {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin-left: 15px;
      }
      .each-slideshow-indicator {
        width: 1rem;
        height: 1rem;
        @apply mr-4 rounded-3xl border-maturity border-2 bg-white opacity-100;
        border: 2px solid theme("colors.maturity.DEFAULT");
        display: inline-block;

        &:before {
          content: none;
        }

        &.active {
          @apply bg-trust;
          border: 2px solid theme("colors.trust.500");
        }
      }
    }
  }
}

.hero-slide {
  align-items: stretch;
  display: flex;
  width: 100%;
  height: 100%;

  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    max-height: 850px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    flex-direction: column;
    padding: 3rem;
  }

  span {
    text-align: left;
    color: white;
  }
  > span {
    width: 100%;
  }

  p {
    margin: 0.5rem 0 2rem 0;
  }
}

.clean-clip-bottom {
  clip-path: polygon(0 0,100% 0,100% calc(100% - 9vw),0 100%);
  margin-bottom: -9vw;
  z-index: 1;
}

@screen md {
  .hero-slide {
    .slide-content {
      span {
        max-width: 65vw;
      }
    }
  }
}

@screen lg {
  .hero-slide {
    & > div {
      background-position: top left;
    }
    .slide-content {
      span {
        max-width: 65vw;
      }
    }
  }
}

@screen xl {
  .hero {
    &.home {
      .dark-side {
        flex-basis: 20%;
      }
    }
  }
}

@screen 2xl {
  .hero-slide {
    .slide-content {
      span {
        max-width: 45vw;
      }
    }
  }
}

@screen 3xl {
  .hero-slide {
    .slide-content {
      span {
        max-width: 40vw;
      }
    }
  }
}

* {
    scroll-behavior: smooth;
    @apply subpixel-antialiased;
}

html {
    font-size: 16px;
}
body {
    font-family: "Montserrat", "Trebuchet MS", sans-serif;
    @apply bg-white font-normal text-default text-base;
    @apply text-maturity-500;
    max-width: 100vw;
    overflow-x: hidden;
    font-weight: 300;
}

strong {
    font-weight: 600;
}
.share-icons {
    button {
        @apply transition-all origin-center;
        &:hover {
            @apply scale-125;
        }
    }
}
main {
    width: 100%;
    // padding-bottom:2rem;
}
.container {
    @apply max-w-screen-xl m-auto;
}

a {
    @apply cursor-pointer;
}

section {
    @apply text-default;
    h2,
    h3 {
        @apply text-trust-500;
    }
    h2 {
        // @apply text-4xl;
    }
    h3 {
        @apply text-xl;
    }
    h4 {
        @apply text-trust-900;
    }
    // .font-semibold, .font-bold {
    //     @apply text-maturity;
    // }

    &.trust {
        @apply text-white text-opacity-80;
        h2,
        h3,
        h4,
        strong,
        .font-semibold,
        .font-bold {
            @apply text-white text-opacity-100;
        }
    }
    

    p {
        @apply py-2;
        strong {
            @apply font-semibold;
        }
    }
}

a.link {
    @apply text-trust;
    &:hover {
        @apply text-trust-900 underline;
    }
}

h1 {
    @apply text-4xl font-extrabold;
    small {
        @apply text-xl;
    }

    @screen lg {
        @apply text-5xl font-extrabold;
        small {
            @apply text-3xl;
        }
    }
}

h2 {
    font-size: 2rem;
    line-height: 1.75rem;
    font-weight: bold;
    @screen lg {
        font-size: 3rem;
        line-height: 2.75rem;
    }
}

h3 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 2rem;
    @screen lg {
        font-size: 3rem;
    }
}

h2,
h3 {
    small {
        font-size: 1rem;
        line-height: 1rem;
        display: block;
    }
    @screen lg {
        small {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 500;
        }
    }
}

.-mt-100px {
    margin-top: -100px #{'!important'};
}
.-mt-150px {
    margin-top: -150px #{'!important'};
}
.-mt-200px {
    margin-top: -200px #{'!important'};
}
.-mt-250px {
    margin-top: -250px #{'!important'};
}

.mt-50px {
    margin-top: 50px #{'!important'};
}
.mt-100px {
    margin-top: 100px #{'!important'};
}
.mt-150px {
    margin-top: 150px #{'!important'};
}

.pt-50px {
    padding-top: 50px #{'!important'};
}
.pt-100px {
    padding-top: 100px #{'!important'};
}
.pt-150px {
    padding-top: 150px #{'!important'};
}
.pt-200px {
    padding-top: 200px #{'!important'};
}
.pt-250px {
    padding-top: 250px #{'!important'};
}




h4 {
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 2rem;
}

.label {
    @apply font-semibold text-xxs px-2 py-1;
    text-transform: uppercase;
    border-radius: 1rem;
    cursor: pointer;
    display: inline-block;

    &.white-full {
        @apply bg-white text-trust-500 border-white;
    }

    &.white-border {
        @apply text-white border-2 border-white;
        border-width: 1px;
    }
}

.content {
    text-align: justify;
    h2 {
        font-size: 2rem;
        margin: 3rem 0 1rem 0;
        color: theme("colors.trust.500");
    }
    h2.title,
    h3 {
        font-size: 1.3rem;
        margin: 2rem 0 0;
        text-transform: none;
        color: theme("colors.trust.500");
    }
    li {
        list-style-type: circle;
        margin-left: 1rem;
        padding-bottom: 0.5rem;
    }
}
// buttons
.btn {
    @apply font-semibold;
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    display: inline-block;

    &.btn-sm {
        @apply px-4 py-2;
    }

    &.btn-md {
        @apply px-8 py-4;
    }

    &.btn-lg {
        @apply px-8 py-4 text-base;
    }

    &:hover {
        text-decoration: none;
    }

    &.action-border {
        @apply border-action text-action border-2;

        &:hover {
            @apply bg-action text-white;
        }
    }

    &.action-full {
        @apply bg-action text-white;
        &:hover {
            @apply bg-action-500;
        }
    }

    &.black-full {
        @apply bg-gray-900 text-white;
        &:hover {
            @apply bg-gray-800;
        }
    }

    &.trust-border {
        @apply border-trust text-trust-500;
        border-width: 2px;

        &:hover {
            @apply bg-trust text-white;
        }
    }

    &.trust-full {
        @apply bg-trust text-white;
        &:hover {
            @apply bg-trust-500;
        }
    }

    &.maturity-border {
        @apply border-maturity text-maturity;
        border-width: 2px;

        &:hover {
            @apply bg-maturity text-white;
        }
    }

    &.maturity-full {
        @apply bg-maturity text-white;
        &:hover {
            @apply bg-maturity-500 text-white;
        }
    }

    &.white-border {
        @apply border-white text-white border-solid;
        border-width: 2px;

        &:hover {
            @apply bg-white text-trust-500;
        }
    }

    &.white-full {
        @apply bg-white text-trust-900 border border-white;

        &:hover {
            @apply bg-transparent text-white;
        }
    }

    &.link {
        @apply bg-white text-trust underline p-0;
    }
}

section {
    .articles {
        @apply grid grid-cols-1;
    }
}

.cookie-manager {
    position: fixed;
    width:100%;

    bottom:0;
    background-color: rgba(0,0,0,0.9);
    color: white;
    z-index: 100;


    h3 {
        font-size: 1.2rem;
    }
    p {
        font-size: 0.8rem;
    }
}
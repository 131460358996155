.Toastify__toast {
    display:flex;
    flex-direction: column;
}

.Toastify__toast-body {
    order:2;
}
.Toastify__close-button {
    display: flex;
    order: 0;
    align-self: flex-end;
}
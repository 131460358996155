.slider {
  .react-slideshow-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .slider-slide {
    align-items: stretch;
    position:relative;
    display: flex;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    > * {
      @apply mx-auto #{!important};
    }
    
    .slider-caption {
      @apply w-full p-4 pt-8 z-10 left-0 absolute bottom-0 text-left font-semibold text-white;
      background: linear-gradient(to top, rgba(0,0,0,.5), rgba(0,0,0,0));
    }
    
  }

  ul {
    &.indicators {
      li {
        width: 1.2rem !important;
        height: 1.2rem !important;
        margin: 0 5px !important;
        button {
          &::before {
            width: 1.2rem !important;
            height: 1.2rem !important;
          }
        }
      }
    }
  }
}
